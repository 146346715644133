import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Injector, numberAttribute, OnInit, Signal } from '@angular/core';
import { injectQueryParams } from 'ngxtension/inject-query-params';
import { Page } from 'src/app/@common/entities/page.interface';
import { ItemsViewService } from 'src/app/@domain/items-view/items-view.service';
import { PlaylistService } from 'src/app/@domain/playlist/application/playlist.service';
import { computedAsync } from '../../../../../@common/rxjs/computed-async';
import { Modal } from '../../../../../@domain/modal/modal.enum';
import { ModalService } from '../../../../../@domain/modal/modal.service';
import { PlaylistInfo } from '../../../../../@generated';
import { PaginationComponent } from '../../../../global/pagination/pagination.component';
import { SpinnerComponent } from '../../../../global/spinner/spinner.component';
import { CreatePlaylistModalComponent } from '../../../../modals/create-playlist-modal/create-playlist-modal.component';
import { CardGridComponent } from '../../../card-grid/card-grid.component';
import { PlaylistItemComponent } from '../../playlist-item/playlist-item.component';
import { AddCardComponent } from '../add-card/add-card.component';

@Component({
    selector: 'app-user-playlist',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ PlaylistItemComponent, PaginationComponent, SpinnerComponent, AsyncPipe, CreatePlaylistModalComponent, CardGridComponent, AddCardComponent ],
    styles: `
        :host {
            position: relative;
            flex: 1;
            display: flex;
            flex-direction: column;
        }
    `,
    template: `
        @if (playlistPage()) {
            <app-card-grid [listView]="!isGridView()">
                <app-add-card text="Add new playlist" (click)="openCreatePlaylistModal()"/>

                @for (playlist of playlistPage()!.results; track playlist) {
                    <app-playlist-item [playlist]="playlist" [listView]="!isGridView()"/>
                }
            </app-card-grid>

            <app-pagination [totalItems]="playlistPage()!.count" [pageSize]="pageSize"/>
        }

        @if (playlistPage()?.results?.length === 0) {
            <div class="mt-2 no-content">You do not have any saved playlists.</div>
        }

        @if (!playlistPage()) {
            <app-spinner/>
        }

        <app-create-playlist-modal/>
    `
})
export class UserPlaylistComponent implements OnInit {
    private playlistService = inject(PlaylistService);
    private itemsViewService = inject(ItemsViewService);
    private modalService = inject(ModalService);
    private injector = inject(Injector);

    public page = injectQueryParams('page', { transform: numberAttribute });
    public isGridView = this.itemsViewService.isGridView;
    public playlistPage!: Signal<Page<PlaylistInfo> | undefined>;
    public pageSize = 8;

    public ngOnInit() {
        this.playlistPage = computedAsync(
            () => this.playlistService.getPlaylists(this.pageSize, this.page() ?? 1),
            { injector: this.injector }
        );
    }

    openCreatePlaylistModal(): void {
        this.modalService.open(Modal.CreatePlaylist);
    }

}
