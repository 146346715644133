import { ChangeDetectionStrategy, Component, effect, HostListener, inject, signal } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { TooltipModule } from 'primeng/tooltip';
import { catchError, of, tap } from 'rxjs';
import { UserService } from 'src/app/@domain/user/application/user.service';
import { ImageDirective } from '../../../../../@common/DOM/image.directive';
import { equalsValidator } from '../../../../../@common/form/equals.validator';
import { strongPasswordValidator } from '../../../../../@common/form/strong-password.validator';
import { UploadUtil } from '../../../../../@domain/upload/upload-util';
import { ErrorComponent } from '../../../../global/error/error.component';
import { ModalBodyComponent } from '../../../../global/modal/modal-body/modal-body.component';
import { ModalComponent } from '../../../../global/modal/modal.component';
import { SpinnerComponent } from '../../../../global/spinner/spinner.component';
import { ProfileHeaderComponent } from '../../../profile/profile-header/profile-header.component';

@Component({
    selector: 'app-user-edit',
    templateUrl: './user-edit.component.html',
    styleUrls: [ './user-edit.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ FormsModule, ImageDirective, FaIconComponent, ErrorComponent, SpinnerComponent, ModalComponent, ModalBodyComponent, ReactiveFormsModule, TooltipModule, ProfileHeaderComponent ]
})
export class UserEditComponent {
    private userService = inject(UserService);
    private toastr = inject(ToastrService);
    private fb = inject(FormBuilder);

    user = this.userService.userProfile;
    profileForm = this.fb.group({
        firstName: [ '', [ Validators.required ] ],
        lastName: [ '', [ Validators.required ] ],
        email: [ { value: '', disabled: true } ],
        image: [ '' ],
        coverPhoto: [ '' ],
    });
    passwordForm = this.fb.group({
        oldPassword: [ '', [ Validators.required ] ],
        newPassword: [ '', [ Validators.required, strongPasswordValidator ] ],
        rePassword: [ '', [ Validators.required ] ],
    }, { validators: [ equalsValidator('newPassword', 'rePassword') ] });
    selectedPictureFile = signal<File | undefined>(undefined);
    profilePictureBase64 = signal<string | undefined>(undefined);
    coverPicture = signal<File | undefined>(undefined);
    coverPicBase64 = signal<string | undefined>(undefined);
    errorMessage = signal('');
    isLoadingProfile = signal(false);
    isLoadingPassword = signal(false);
    faImage = faImage;

    constructor() {
        effect(() => {
            const user = this.userService.userProfile();
            this.profileForm.patchValue({
                firstName: user?.first_name,
                lastName: user?.last_name,
                email: user?.email,
            });
        });
    }

    @HostListener('drop', [ '$event' ])
    public async ondrop(event: any) {
        event.preventDefault();
        event.stopPropagation();
        await this.selectCoverPhoto(event);
    }

    async onProfilePicSelected(event: any) {
        const file = event.target.files[0];
        this.selectedPictureFile.set(file);

        if (!file || !UploadUtil.isValidImage(file)) {
            this.coverPicBase64.set('');
            return;
        }

        this.profilePictureBase64.set(await UploadUtil.convertToBase64(file));
    }

    async selectCoverPhoto(event: any) {
        const file = event.target.files[0];
        this.coverPicture.set(file);

        if (!file || !UploadUtil.isValidImage(file)) {
            this.coverPicBase64.set('');
            return;
        }

        this.coverPicBase64.set(await UploadUtil.convertToBase64(file));
    }

    updateProfile(): void {
        if (this.profileForm.invalid) {
            return;
        }

        this.isLoadingProfile.set(true);
        this.userService.updateProfile({
            first_name: this.firstName!.value!,
            last_name: this.lastName!.value!,
        }).pipe(
            tap(() => this.toastr.success('Profile updated successfully!')),
            catchError(() => of(null))
        ).subscribe(() => this.isLoadingProfile.set(false));
    }

    updatePassword() {
        if (this.passwordForm.invalid) {
            return;
        }

        this.isLoadingPassword.set(true);

        this.userService.updatePassword({
            old_password: this.oldPassword!.value!,
            new_password: this.rePassword!.value!,
        }).pipe(
            tap(() => this.toastr.success('Password updated successfully!')),
            catchError(() => {
                this.errorMessage.set('Invalid password provided!');
                return of(null)
            })
        ).subscribe(() => {
            this.passwordForm.reset();
            this.isLoadingPassword.set(false)
        });
    }

    get firstName() {
        return this.profileForm.get('firstName');
    }

    get lastName() {
        return this.profileForm.get('lastName');
    }

    get image() {
        return this.profileForm.get('image');
    }

    get coverPhoto() {
        return this.profileForm.get('coverPhoto');
    }

    get oldPassword() {
        return this.passwordForm.get('oldPassword');
    }

    get newPassword() {
        return this.passwordForm.get('newPassword');
    }

    get rePassword() {
        return this.passwordForm.get('rePassword');
    }

}
