import { afterNextRender, ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { NavigationEnd, NavigationError, Router, RouterOutlet } from '@angular/router';
import { AppConfig } from './@common/configuration/app-config.service';
import { ImageDirective } from './@common/DOM/image.directive';
import { ModalService } from './@domain/modal/modal.service';

@Component({
    selector: 'app-root',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        RouterOutlet,
        ImageDirective
    ],
    template: `
        <router-outlet/>`
})
export class AppComponent {
    private modalService = inject(ModalService);
    private appConfig = inject(AppConfig);
    private router = inject(Router);

    constructor() {
        console.debug('Version:', this.appConfig.version);

        afterNextRender(() => {
            document.body.addEventListener('click', (e: Event) => {
                this.modalService.checkModalWindow(e);
            });

            this.router.events.subscribe(evt => {
                if (evt instanceof NavigationEnd) {
                    this.modalService.close()
                }

                if (evt instanceof NavigationError && evt.error.message.includes('dynamically imported')) {
                    window.location.assign(evt.url);
                }
            });
        })
    }
}
