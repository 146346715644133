import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'transformDateDay',
    standalone: true,
})
export class TransformDateDayPipe implements PipeTransform {
  transform(date: any): any {
    if (date) {
      let day: string = '';
      day = date.split(' ')[1].split(',')[0];
      return day;
    } else {
      return;
    }
    //return null;
  }
}
