import { Component, input } from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-add-card',
    standalone: true,
    imports: [ FaIconComponent ],
    styles: `
        @import "../../../../../../styles/variables";

        .card-item {
            background-color: #e5e7eb;
            min-height: 280px;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &:hover {
                background-color: $light-grey;
            }

            .content {
                color: #6b7280;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 0.5rem;
            }

            fa-icon {
                font-size: 2rem;
            }
        }
    `,
    template: `
        <div class="card-item">
            <div class="content">
                <fa-icon [icon]="faPlusCircle"/>
                <p class="bold">{{ text() }}</p>
            </div>
        </div>
    `
})
export class AddCardComponent {
    public text = input.required<string>();
    public faPlusCircle = faPlusCircle;
}
