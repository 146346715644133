import { AsyncPipe, SlicePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { EventService } from 'src/app/@domain/event/application/event.service';
import { SpinnerComponent } from '../../../../global/spinner/spinner.component';
import { CardGridComponent } from '../../../card-grid/card-grid.component';
import { EventItemComponent } from '../../events/event-item/event-item.component';

@Component({
    selector: 'app-events-section',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ EventItemComponent, SpinnerComponent, AsyncPipe, SlicePipe, CardGridComponent ],
    styles: `
        @import "../../../../../../styles/functions";

        :host {
            display: block;
            width: 100%;
            min-height: 300px;
            padding-bottom: 1rem;
        }
    `,
    template: `
        @if (events$ | async; as events) {
            <app-card-grid [listView]="false" [maxCards]="3">
                @for (event of events | slice: 0:3; track event) {
                    <app-event-item [event]="event"/>
                }
            </app-card-grid>
        } @else {
            <app-spinner/>
        }
    `
})
export class EventsSectionComponent {
    private eventService = inject(EventService);

    public events$ = this.eventService.getLatestEvents();
}
