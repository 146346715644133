import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { filter, map, startWith } from 'rxjs';
import { AdminRoute } from '../../../routing/types/admin-route';
import { HeaderComponent } from '../header/header.component';

@Component({
    selector: 'app-admin-layout',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        HeaderComponent,
        RouterOutlet,
        RouterLink,
        RouterLinkActive,
        AsyncPipe,
        ToastModule,
        ConfirmDialogModule
    ],
    styles: `
        ::ng-deep {
            @import "../../../../styles/admin/admin";
        }

        :host {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100dvh;
        }

        main {
            padding-top: 4.75rem;
            min-height: 100dvh;
            display: flex;
            overflow: hidden;
        }

        .sidebar {
            min-width: 330px;
            background-color: rgb(255, 255, 255);
            padding: 1.5rem 1rem;
            display: flex;
            flex-direction: column;
            gap: 0.25rem;
            box-shadow: rgba(145, 158, 171, 0.2) 0px 8px 10px -5px,
            rgba(145, 158, 171, 0.14) 0px 16px 24px 2px,
            rgba(145, 158, 171, 0.12) 0px;
            border-right: 1px solid rgba(145, 158, 171, 0.2);
        }

        .sidebar-link {
            padding: 0.75rem 1rem;
            border-radius: 0.25rem;
            text-decoration: none;
            color: #333;
            font-weight: 500;

            &:hover {
                background-color: rgba(145, 158, 171, 0.08);
            }

            &.active {
                background-color: #ddd;
            }
        }

        .content {
            flex: 1;
            padding: 1rem 2rem 5rem;
            overflow-y: auto;
        }

        .header {
            font-size: 1.2rem;
            font-weight: 600;
            margin-bottom: 1.5rem;
        }
    `,
    template: `
        <app-header query="''"/>
        <main>
            <div class="sidebar">
                @for (item of sidebarItems; track item.title) {
                    <a [routerLink]="item.route" routerLinkActive="active" class="sidebar-link">
                        {{ item.title }}
                    </a>
                }
            </div>
            <div class="content">
                <div class="header">{{ header$ | async }}</div>
                <router-outlet/>
            </div>
        </main>
        <p-toast/>
        <p-confirmDialog/>
    `
})
export class AdminLayout {
    private activatedRoute = inject(ActivatedRoute);
    private router = inject(Router);

    public header$ = this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        startWith(null),
        map(() => this.activatedRoute.firstChild?.snapshot.data.header as string),
    );
    public readonly sidebarItems = [
        { title: 'Videos', route: AdminRoute.Videos },
        { title: 'Authors', route: AdminRoute.Authors },
        { title: 'Events', route: AdminRoute.Events },
        { title: 'Users', route: AdminRoute.Users },
        { title: 'Incoming', route: AdminRoute.Incoming }
    ] as const;
}
