<header class="header white-background">
    <div class="header-content container">
        <div class="logo">
            <a [routerLink]="AppRoutes.Home">
                <picture>
                    <source srcset="../../../../assets/vln_default.svg" media="(min-width: 851px)" />
                    <img src="../../../../assets/vln_tiny.svg" alt="logo" loading="eager" />
                </picture>
            </a>
        </div>
        <form class="search" (submit)="onSubmit()">
            <fa-icon [icon]="faSearch" (click)="onSubmit()" />
            <input #searchBar type="search" placeholder="Search" name="text" [ngModel]="query()" />
        </form>
        <div class="buttons-parent menu" (click)="isMenuOpen.set(false)" [class.hide]="!isBrowser">
            @if (!isAuthenticated()) {
                <div class="buttons not-logged-in" [class.open]="isMenuOpen()">
                    <a routerLink="/{{AppRoutes.Login}}" class="btn btn-trans">Sign in</a>
                    <a routerLink="/{{ AppRoutes.Register}}" class="btn btn-red border-radius-small">Sign up</a>
                </div>
            } @else {
                <div class="buttons logged-in" [class.open]="isMenuOpen()">
                    @if (userProfile(); as profile) {
                        <div>
                            <img src="/frontend/src/assets/character.png" alt="user profile image" loading="eager"
                                 class="user-avatar" (click)="toggleProfileMenu()" />
                            @if (isSignedInMenuOpen()) {
                                <div class="overlay">
                                    <div class="name">Signed in as
                                        <b>{{ profile.email || profile.username }}</b>
                                    </div>
                                    <a routerLink="/{{AppRoutes.Profile}}" class="btn">Your profile</a>
                                    @if (isAdmin()) {
                                        <a routerLink="/{{AdminRoute.Admin}}" class="btn">Admin panel</a>
                                    }
                                    <button (click)="onLogout()" class="btn">Sign out</button>
                                </div>
                            }
                        </div>
                    }
                </div>
            }
        </div>
        @if (!isAuthenticated()) {
            <div class="burger" [class.closed]="isMenuOpen()" (click)="isMenuOpen.set(!isMenuOpen())">
                <div></div>
                <div></div>
                <div></div>
            </div>
        }
    </div>
</header>
