import { Route } from '@angular/router';
import { AdminRoute } from './types/admin-route';

export const ADMIN_ROUTES: Route[] = [
    {
        path: AdminRoute.Videos,
        loadComponent: () => import('../@page/admin/video/list/video-list.page'),
        data: {
            header: 'Videos',
        }
    },
    {
        path: AdminRoute.Videos + '/new',
        loadComponent: () => import('../@page/admin/video/manage/video-manage.page'),
        data: {
            header: 'Create Video'
        }
    },
    {
        path: AdminRoute.Videos + '/:id',
        loadComponent: () => import('../@page/admin/video/manage/video-manage.page'),
        data: {
            header: 'Edit Video',
        }
    },
    {
        path: AdminRoute.Videos + '/:id/:part/sync/:language',
        loadComponent: () => import('../@page/admin/video/synchronization/video-synchronization.page'),
    },
    {
        path: AdminRoute.Authors,
        loadComponent: () => import('../@page/admin/author/list/author-list.page'),
        data: {
            header: 'Authors',
        }
    },
    {
        path: AdminRoute.Authors + '/new',
        loadComponent: () => import('../@page/admin/author/manage/author-manage.page'),
        data: {
            header: 'Create Author'
        }
    },
    {
        path: AdminRoute.Authors + '/:id',
        loadComponent: () => import('../@page/admin/author/manage/author-manage.page'),
        data: {
            header: 'Edit Author',
        }
    },
    {
        path: AdminRoute.Events,
        loadComponent: () => import('../@page/admin/event/list/event-list.page'),
        data: {
            header: 'Events',
        }
    },
    {
        path: AdminRoute.Events + '/new',
        loadComponent: () => import('../@page/admin/event/manage/event-manage.page'),
        data: {
            header: 'Create Event'
        }
    },
    {
        path: AdminRoute.Events + '/:id',
        loadComponent: () => import('../@page/admin/event/manage/event-manage.page'),
        data: {
            header: 'Edit Event',
        }
    },
    {
        path: AdminRoute.Events + '/:id/sections',
        loadComponent: () => import('../@page/admin/event/sections/event-sections.page'),
        data: {
            header: 'Edit Sections',
        }
    },
    {
        path: AdminRoute.Users,
        loadComponent: () => import('../@page/admin/user/list/user-list.page'),
        data: {
            header: 'Users',
        }
    },
    {
        path: AdminRoute.Users + '/new',
        loadComponent: () => import('../@page/admin/user/manage/user-manage.page'),
        data: {
            header: 'Create User'
        }
    },
    {
        path: AdminRoute.Users + '/:id',
        loadComponent: () => import('../@page/admin/user/manage/user-manage.page'),
        data: {
            header: 'Edit User'
        }
    },
    {
        path: AdminRoute.Incoming,
        loadComponent: () => import('../@page/admin/incoming/list/incoming.page'),
        data: {
            header: 'Incoming',
        }
    }
]
