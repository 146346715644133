import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { FooterComponent } from '../footer/footer.component';
import { HeaderComponent } from '../header/header.component';

@Component({
    selector: 'app-layout',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        HeaderComponent,
        FooterComponent,
        RouterOutlet
    ],
    styles: `
        :host {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        main {
            padding-top: 4.5rem;
            min-height: 100dvh;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .content {
            flex: 1;
        }
    `,
    template: `
        <app-header />
        <main class="content">
            <router-outlet/>
        </main>
        <app-footer />
    `
})
export class AppLayout {
}
