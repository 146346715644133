import { Routes } from '@angular/router';
import { AdminLayout } from '../@ui/layout/admin/admin.layout';
import { AppLayout } from '../@ui/layout/app/app.layout';
import { ADMIN_ROUTES } from './admin.routes';
import { APP_ROUTES } from './app.routes';
import { adminGuardFn } from './guards/admin.guard';
import { authGuardFn } from './guards/auth.guard';
import { slugGuardFn } from './guards/slug.guard';
import { AdminRoute } from './types/admin-route';
import { AppRoutes } from './types/app-route';

export enum GenericRoute {
    NotFound = 'not-found',
    Unauthorized = 'unauthorized',
    Slug = ':slug',
    Error = 'error'
}

export const ROUTES: Routes = [
    {
        path: AdminRoute.Admin,
        canActivate: [ authGuardFn, adminGuardFn ],
        component: AdminLayout,
        loadChildren: () => ADMIN_ROUTES
    },
    {
        path: '',
        component: AppLayout,
        loadChildren: () => [
            ...APP_ROUTES,
            {
                path: GenericRoute.NotFound,
                loadComponent: () => import('../@page/app/not-found/not-found.page')
            },
            {
                path: GenericRoute.Unauthorized,
                loadComponent: () => import('../@page/app/unauthorized/unauthorized.page')
            },
            {
                path: GenericRoute.Error,
                loadComponent: () => import('../@page/app/error/error.page')
            },
            {
                path: AppRoutes.Slug,
                pathMatch: 'full',
                canActivate: [ slugGuardFn ],
                loadComponent: () => import('../@page/app/not-found/not-found.page')
            },
            {
                path: '**',
                redirectTo: GenericRoute.NotFound
            }
        ]
    }
];
