import { AsyncPipe, DatePipe } from '@angular/common';
import { Component, input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ImageDirective } from '../../../../@common/DOM/image.directive';
import { PlaylistInfo } from '../../../../@generated';
import { AppRoutes } from '../../../../routing/types/app-route';

@Component({
    selector: 'app-playlist-item',
    standalone: true,
    imports: [ RouterLink, AsyncPipe, ImageDirective, DatePipe ],
    styles: `
        @import "../../../../../styles/variables";

        .separator {
            flex: 1;
        }

        // LIST VIEW
        .list-view {
            display: flex;
            align-items: center;
            margin-bottom: 1rem;
            padding: 0.5rem;
            border-radius: 4px;
            cursor: pointer;

            img {
                height: 60px;
                width: 150px;
                margin-right: 1.5rem;
                border-radius: 4px;
                object-fit: cover;
                transform: scale(1.05);
            }

            .right {
                display: flex;
                align-items: center;
                width: 100%;

                .info {
                    width: 60%;

                    strong {
                        color: $dark;

                        margin-right: 1.5rem;
                    }
                }

                .numbers {
                    display: flex;
                    width: 40%;

                    p {
                        width: 50%;
                    }
                }

                @media screen and (max-width: 767px) {
                    flex-direction: column;

                    .info {
                        width: 100%;
                        margin-right: 0;
                    }

                    .numbers {
                        width: 100%;
                        flex-direction: column;

                        p {
                            width: fit-content;
                            margin-right: 1rem;
                        }
                    }
                }

                @media screen and (max-width: 600px) {
                    .info {
                        font-size: 16px;
                    }

                    .numbers {
                        p {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    `,
    template: `
        @if (!listView()) {
            <a class="card-item" routerLink="/{{AppRoutes.Playlists}}/{{playlist().id}}">
                <div class="card-item-header">
                    <img [src]="playlist().thumbnail" alt="playlist-img" loading="eager"/>
                </div>
                <div class="card-item-body">
                    <h3>{{ playlist().title }}</h3>
                    <div class="separator"></div>
                    <div>
                        <p>{{ playlist().total_videos }} Videos</p>
                        <p>Updated {{ playlist().created }}</p>
                    </div>
                </div>
            </a>
        } @else {
            <a class="list-view" routerLink="/{{AppRoutes.Playlists}}/{{playlist().id}}">
                <img [src]="playlist().thumbnail || 'assets/download.png'" alt="video-img" loading="lazy"/>
                <div class="right">
                    <div class="info">
                        <strong>{{ playlist().title }}</strong>
                    </div>
                    <div class="numbers">
                        <p>Updated {{ playlist().created | date }}</p>
                        <p>{{ playlist().total_videos }} Videos</p>
                    </div>
                </div>
            </a>
        }
    `
})
export class PlaylistItemComponent {
    public playlist = input.required<PlaylistInfo>();
    public listView = input.required<boolean>();
    public AppRoutes = AppRoutes;
}
